$background-color: #edf1f5;
$block-background-color: white;
$text-color: #6d6d6d;

.main {
  background-color: $background-color;
  // padding: 20px;
  // border-radius: 5px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  .title {
    color: $text-color;
    // font-size: 24px;
    // font-weight: bold;
    // margin-bottom: 20px;
  }
}

.block {
  background-color: $block-background-color;
  color: $text-color;
}
